












































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Admin } from '@/api'
import { FormHelper } from '@/mixins'
import { GameModule } from '@/store/modules'
import { SearchType } from '@/types/enums'
import {
  NUMBERS_ONLY_REGEX,
  GUID_REGEX,
  IP_ADDRESS_REGEX,
  HARDWARE_ID_REGEX
}from '@/services/Validation/regexes'

type FindAltResultData = {
  masterAccountIds: string[]
  hardwareIds: string[]
  ips: string[]
  accountIds: number[]
  characterIds: number[]
}

@Component({
  components: {
    CharacterCard: () => import('@/components/Character/CharacterCard.vue')
  }
})
export default class FindAlts extends Mixins(FormHelper) {
  @GameModule.Action getCharacters

  search: string = ''
  logType = null
  logTypes: any[] = []
  from = ''
  to = ''
  data: Nullable<FindAltResultData> = null
  characters: Character[] = []
  noResults = false
  shortLogs = false
  forceReload = false
  error = false
  errorMessage = ''
  searchTypes = [
    { value: SearchType.CHARACTER_ID, label: 'Character ID', regex: NUMBERS_ONLY_REGEX },
    { value: SearchType.IP_ADDRESS, label: 'IP Address', regex: IP_ADDRESS_REGEX },
    { value: SearchType.HARDWARE_ID, label: 'Hardware ID', regex: HARDWARE_ID_REGEX },
    { value: SearchType.MASTER_ACCOUNT_ID, label: 'Master Account ID', regex: GUID_REGEX }
  ]

  @Watch('search')
  @Watch('searchType.value', { deep: true })
  onCharacterInfoChange() {
    this.data = null
    this.characters = []
    this.error = false
    this.errorMessage = ''
  }

  get findAltResults() {
    return [
      { name: 'Accounts Ids', type: 'Account ID', data: this.data?.accountIds, class: 'accIds' },
      { name: 'Master Accounts Ids', type: 'Master Account ID', data: this.data?.masterAccountIds, class: 'masterAccIds' },
      { name: 'Hardware Ids', type: 'Hardware ID', data: this.data?.hardwareIds, class: 'hwids' },
      { name: 'IP addresses', type: 'IP Address', data: this.data?.ips, class: 'ips' },
    ]
  }

  async searchAlts() {
    try {
      let searchType: Nullable<SearchType> = null

      this.searchTypes.forEach(st => {
        if (st.regex.test(this.search)) {
          searchType = st.value
        }
      })

      this.data = (await Admin.searchAltData({
        search: this.search,
        searchType
      })).data

      this.characters = await this.getCharacters({
        data: this.data!.characterIds,
        APIType: 'GM'
      })
    } catch(e) {
      this.error = true
      this.errorMessage = `${this.$t(`RESPONSE_MESSAGES_${(this.data as any).message.key}`)} ${this.search}`
    }
  }
}
